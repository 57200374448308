import WorksheetResultsItem from './WorksheetResultsItem';

import styles from './styles.module.scss';

const WorksheetResults = ({ results }) => {

  return (
    <div className={styles.worksheetResults}>
      <div className={styles.worksheetResultsContainer}>
        <p>{results?.headline}</p>
        <p className={styles.worksheetResultsContainerDescription}>{results?.description}</p>
        <ul className={styles.worksheetResultsContainerDescription}>
          {results?.results?.map((item, index) => (
            <WorksheetResultsItem item={item} key={index}/>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default WorksheetResults