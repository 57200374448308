import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { fetchData, postData } from "../../utils/api";

const initialState = {
  worksheetList: [],
};

export const worksheetSlice = createSlice({
  name: "worksheet",
  initialState,
  reducers: {
    appendWorksheet: (state, action) => {
      state.worksheetList.unshift({ ...action.payload })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorksheetByDate.pending, (state, action) => {
        console.log('pending get worksheet', action)
      
      })
      .addCase(getWorksheetByDate.fulfilled, (state, action) => {
        state.worksheetList = action.payload;
      })
  }
});

export const getWorksheetByDate = createAsyncThunk(
  "/work_history_date",
  async (date) => 
    await fetchData(`/work_history/${date}`)
)

export const { appendWorksheet } = worksheetSlice.actions;
export const worksheetReducer = worksheetSlice.reducer;


