import cx from "classnames";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../../hooks/useQuery";

import WorkHistoryItemIcon from "../../../../assets/icons/workHistoryItem.png";

import styles from "./styles.module.scss";

const WorkHistoryItem = ({ date }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const todayISO = dayjs().startOf('day').format('YYYY-MM-DDT00:00:00.00+00:00');

  const onSelectDate = () => {
    query.set(`date`, date);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  }
  const selectedDate = query.get("date") ?? todayISO;

  const isActiveHistory = useMemo(() => dayjs(date).startOf('day').isSame(dayjs(selectedDate, 'MMMM D, YYYY').startOf('day').toISOString()))

  return <div className={cx(styles.workHistory, isActiveHistory ? styles.workHistoryActive : '')} onClick={() => onSelectDate()}>
    <img src={WorkHistoryItemIcon} alt={'work history icon'} />
    <p>{dayjs(date).isSame(todayISO) ? 'Today' : dayjs(date).format('MMMM D, YYYY')}</p>
  </div>
}

export default WorkHistoryItem