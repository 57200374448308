
import { useMemo } from 'react';

import excel from '../../../../assets/icons/excel.png';

import styles from './styles.module.scss';

const WorksheetTracker = ({ tracker }) => {

  const fileName = useMemo(() => tracker?.buyer_name ? `Buyer Tracker for ${tracker.buyer_name.replace(/\b[a-z]/g, function (f) { return f.toUpperCase() })} -` : 'Search Results -', [tracker]);

  return (
    <div className={styles.worksheetTracker}>
      <div className={styles.worksheetTrackerContainer}>
        <p>{tracker?.headline}</p>
        <p className={styles.worksheetTrackerContainerDescription}>{tracker?.description}</p>
        <div className={styles.worksheetTrackerFile}>
          <img src={excel} alt='excel' />
          <div>
            <p>{fileName} </p>
          </div>
          <a href={tracker?.url} target='_blank'>Open File</a>
        </div>
      </div>
    </div>
  )
}

export default WorksheetTracker

