import { ReactComponent as QuickLinksIcopn } from "../../assets/icons/quickLinks.svg";
import QuickLinksItem from "./components/QuickLinksItem/QuickLinksItem";

import styles from "./styles.module.scss";

const QuickLinks = () => {
  return <div className={styles.quickLinks}>
    <div className={styles.quickLinksHeader}>
      <p>QuickLinks
      </p>
      <QuickLinksIcopn />
    </div>
    <div className={styles.quickLinksContainer}>
      <QuickLinksItem name={'Master Client List'} url={"https://docs.google.com/spreadsheets/d/1npu43V4V8p8xLYNbuNn8Lg-PppTJgMazMWlnJrTOzjc/edit?gid=0#gid=0"} />
    </div>
  </div>
}

export default QuickLinks