import { useState, useEffect } from 'react';

const useIsMobile = (breakpoint = 1024) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

  const checkWindowSize = () => {
    setIsMobile(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkWindowSize(); // Initial check
    window.addEventListener('resize', checkWindowSize); // Add resize listener

    return () => {
      window.removeEventListener('resize', checkWindowSize); // Cleanup
    };
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
