
import { useState } from 'react';

import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg';

import styles from './styles.module.scss';

const WorksheetResultsItem = ({ item }) => {
  const [minified, setMinified] = useState(true);

  return (
    <div className={styles.worksheetResultsItem}>
      <div className={styles.worksheetResultsItemContainer}>
        <a href={item?.url} target='_blank'>{item?.name}</a>
        <div className={styles.worksheetResultsSeparator} />
        <p>{item?.location}</p>
        <div className={styles.worksheetResultsSeparator} />
        <p>{item?.price}</p>
        <Arrow onClick={() => setMinified(!minified)} style={{ transform: minified ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </div>
      {
        !minified &&
        <div className={styles.worksheetResultsItemMinifiedContainer}>
          <ul>
            <li key={item.location}><span>📍 Location: </span>{item?.location}</li>
            <li key={item.size}><span>📏 Size:</span> {item?.size}</li>
            <li key={item.price}><span>💰 Listed Price:</span> {item?.price}</li>
            <li key={item.status}><span>🚦 Status:</span> {item?.status}</li>
            <li key={item.url}><span>🔗 Listed on:</span> <a href={item?.url} target='_blank'>{new URL(item?.url).hostname.replace('www.', '').replace('.com', '')}</a></li>
          </ul>
          <div className={styles.worksheetResultsItemMinifiedContainerReason}><span>💡 Reasoning: </span>{item?.reason}</div>
        </div>
      }
    </div>
  )
}

export default WorksheetResultsItem