import { useState } from 'react';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg';

import styles from './styles.module.scss';

const WorksheetProcess = ({ process }) => {
  const [minified, setMinified] = useState(true);

  return (
    <div className={styles.worksheetProcess}>
      <div className={styles.worksheetProcessContainer}>
        <p><Arrow onClick={() => setMinified(!minified)} style={{ transform: minified ? 'rotate(0deg)' : 'rotate(180deg)' }} />{process?.headline}  <span onClick={() => setMinified(!minified)}>{minified ? 'Show' : 'Hide'} </span></p>
        {process?.description && <p className={styles.worksheetProcessContainerDescription}>{process?.description}</p>}
        {
          !minified && <ul className={styles.worksheetProcessContainerList}>
            {process?.steps?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        }
      </div>
    </div>
  )
}

export default WorksheetProcess