import dayjs from 'dayjs';
import { io } from 'socket.io-client';

import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../context/AuthContext';
import { useQuery } from '../hooks/useQuery';

import { appendMessage, setLoadingState, setWebsocketState, replaceMessage } from '../store/slices/chatSlice';
import { CHAT_SENDER } from '../utils/chatUtils';
import { appendWorksheet } from '../store/slices/worksheetSlice';

const URL = `${process.env.REACT_APP_BASE_URL}`;

const EVENTS_ACTION_TYPE = {
  RESPONSE: 'response',
  NEW_MESSAGE: 'new_message',
  MESSAGE_HISTORY: 'message_history',
  RESPONSE_ERROR: 'error'
};

export const SocketContext = createContext();

const WebsocketWrapper = ({
  children
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { token, currentUser } = useAuth();
  const [websocket, setWebsocket] = useState();

  let websocketConnectedCount = 0;
  const accessToken = token ?? false;
  const selectedDate = query.get("date") ?? '';

  const onResponse = async (value) => {
    value?.message && dispatch(appendMessage({ sender: CHAT_SENDER.AGENT, message: value.message }))
    value?.worksheet && dispatch(appendWorksheet(value?.worksheet))
    dispatch(setLoadingState(value?.in_progress ?? false))
  };

  const onResponseError = async (value) => {
    dispatch(appendMessage({ sender: CHAT_SENDER.AGENT, message: value?.message ?? 'Cannot process the request.' }));
    dispatch(setLoadingState(false));
  };

  const onMessageHistory = async (value) => {
    dispatch(replaceMessage(value.messages));
    dispatch(setLoadingState(false));
  };

  useEffect(() => {
    if (accessToken && currentUser) {
      const socket = io(URL, {
        autoConnect: false,
        transports: ["websocket"],
        auth: {
          token: `Bearer ${accessToken}`,
          uid: currentUser.uid
        },
      });
      setWebsocket(socket);
    }
  }, [accessToken, currentUser]);

  useEffect(() => {
    if (accessToken && currentUser) {
      websocket?.emit('get_message_history', {
        message: { date: selectedDate },
        id: `${websocket.id}${Math.random()}`,
        socketID: websocket.id,
      });
      dispatch(setLoadingState(true))
    }
  }, [websocket, selectedDate, currentUser, accessToken]);

  useEffect(() => {
    const todayISO = dayjs().startOf('day').format('YYYY-MM-DDT00:00:00+00:00');
    const lastVisit = localStorage.getItem('lastVisit');
    if (!lastVisit || !dayjs(lastVisit)?.isSame(todayISO)) {
      if (websocketConnectedCount == 0 && websocket) {
        websocket.emit('start', {
          message: {},
          id: `${websocket.id}${Math.random()}`,
          socketID: websocket.id,
        });
        dispatch(setLoadingState(true))
        localStorage.setItem('lastVisit', todayISO);
      }
    }
  }, [websocketConnectedCount, websocket, dispatch]);

  const onWebsocketConnect = () => {
    dispatch(setWebsocketState(true));
    websocketConnectedCount = + 1;
  };
  const onWebsocketDisconnect = () => dispatch(setWebsocketState(false));

  useEffect(() => {
    if (websocket) {
      websocket.connect()
      websocket.on(EVENTS_ACTION_TYPE.RESPONSE, onResponse);
      websocket.on(EVENTS_ACTION_TYPE.RESPONSE_ERROR, onResponseError);
      websocket.on(EVENTS_ACTION_TYPE.MESSAGE_HISTORY, onMessageHistory);
      websocket.on('connect', onWebsocketConnect);
      websocket.on('disconnect', onWebsocketDisconnect);

      return () => {
        websocket.off(EVENTS_ACTION_TYPE.RESPONSE, onResponse);
        websocket.off(EVENTS_ACTION_TYPE.RESPONSE_ERROR, onResponseError);
        websocket.of(EVENTS_ACTION_TYPE.MESSAGE_HISTORY, onMessageHistory);
        websocket.disconnect();
      };
    }
  }, [websocket]);

  return (
    <SocketContext.Provider value={{ websocket }}>
      {children}
    </SocketContext.Provider >
  )
}


export default WebsocketWrapper;
