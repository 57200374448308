import styles from "./styles.module.scss";
import excel from "../../../../assets/icons/excel.png";

const QuickLinksItem = ({name, url}) => {
  return <div className={styles.quickLink} onClick={() => window.open(url, '_blank')}>
      <img src={excel} alt={'work history icon'} />
      <p>{name}</p>
  </div>
}

export default QuickLinksItem