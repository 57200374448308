import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { fetchData, postData } from "../../utils/api";

const initialState = {
  workHistory: []
};

export const workHstorySlice = createSlice({
  name: "workHistory",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(workHistory.pending, (state, action) => {
        console.log('pending get workhistory')
      })
      .addCase(workHistory.fulfilled, (state, action) => {
        state.workHistory= action.payload
      })
  }
});

export const workHistory = createAsyncThunk(
  "/work_history",
  async () =>
    await fetchData(`/work_history`)
)

export const { } = workHstorySlice.actions;
export const workHistoryReducer = workHstorySlice.reducer;
