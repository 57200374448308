import { useSelector } from 'react-redux';
import ChatMessage from '../ChatMessage/ChatMessage';
import { CHAT_SENDER } from '../../../../utils/chatUtils';

import styles from './styles.module.scss';
import { useEffect, useRef, useState } from 'react';
import './loading-animation.scss'

const ChatComponent = () => {
  const { messages, loading } = useSelector((state) => state.chat);
  const chatContainer = useRef()
  const [size, setSize] = useState({ width: 0, height: 0 });

  const scrollToBottom = () => {
    chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages, size])

  useEffect(() => {
    const handleResize = () => {
      if (chatContainer.current) {
        const { width, height } = chatContainer.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (chatContainer.current) {
      resizeObserver.observe(chatContainer.current);
    }
    return () => {
      resizeObserver.disconnect();
    }
  }, []);

  return <div className={styles.chatComponent} ref={chatContainer}>
    {messages.map(({ sender, message }, index) => <ChatMessage message={message} isSent={sender === CHAT_SENDER.USER} key={index} />)}
    {loading && <div className="chat-bubble">
      <div id="loading-animation" className="loading-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>}
  </div>
}

export default ChatComponent