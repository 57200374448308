import useIsMobile from '../../hooks/useIsMobile';
import { useEffect } from 'react';
import { workHistory } from '../../store/slices/workHistorySlice';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../hooks/useQuery';

import WebsocketWrapper from '../../websocket/WebsocketWrapper';
import { AgentChat, QuickLinks, WorkHistory, Worksheet } from '../../pageComponents';

import styles from './styles.module.scss';

const Dashboard = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const selectedDate = query.get("date") ?? '';

  useEffect(() => {
    dispatch(workHistory(selectedDate))
  }, []);

  const isMobileView = useIsMobile();
  return (
    <div className={styles.dashboardContainer}>
      <WebsocketWrapper>
        <AgentChat />
      </WebsocketWrapper>
      <Worksheet />
      {!isMobileView &&
        <div style={{ height: '100%', width: '300px' }}>
          <WorkHistory />
          <QuickLinks />
        </div>
      }
    </div>
  );
}

export default Dashboard;
