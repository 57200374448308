import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { chatReducer } from './slices/chatSlice';
import { worksheetReducer } from "./slices/worksheetSlice";
import { workHistoryReducer } from "./slices/workHistorySlice";

const reducers = combineReducers({
  chat: chatReducer,
  worksheet: worksheetReducer,
  workHistory: workHistoryReducer
});


export const globalStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware() // includes the thunk middleware
});

export const RootState = globalStore.getState();
