// AuthContext.js
import React, { useContext, useEffect, useState } from 'react';
import { auth, googleProvider, firestore } from '../utils/firebase';
import { onAuthStateChanged, signOut, getIdToken, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore"; // For Firestore


const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoginFlow, setIsLoginFlow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!isLoginFlow) {
        if (user) {
          setCurrentUser(user);
          setLoading(false);
          const userToken = await getIdToken(user);
          setToken(userToken);
        } else {
          setCurrentUser(null);
          setToken(null);
          setLoading(false);
        }
      }
    });

    return () => unsubscribe();
  }, [isLoginFlow]);

  // Google Sign-In Function
  const signInWithGoogle = async () => {
    setIsLoginFlow(true);
    try {
      // Trigger Google Sign-In
      const result = await signInWithPopup(auth, googleProvider);

      // Get the user's email
      const email = result.user.email;
      // Check if the email exists in Firestore whitelist
      const docRef = doc(firestore, "whitelisted-emails", email); // Document ID is the email
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIsLoginFlow(false);
        console.log("User is whitelisted, logging in...");
        return result.user; // Proceed with login
      } else {
        logout()
        throw new Error('User is not whitelisted.');
      }
    } catch (error) {
      console.error("Error during sign-in:", error.message);
      setIsLoginFlow(false);
      throw error;
    }
  };

  const logout = async () => {
    try {
      setToken(null);
      setCurrentUser(null);
      await signOut(auth).then(() => setIsLoginFlow(false));
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };


  const value = {
    currentUser,
    logout,
    token,
    signInWithGoogle
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
