
import styles from './styles.module.scss';

const WorksheetTask = ({ items, headline }) => {
  return <div className={styles.worksheetTask}>
    <div className={styles.worksheetTaskContainer}>
      <p>{headline}</p>
      <ul className={styles.worksheetTaskContainerDescription}>
        {items?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  </div>
}

export default WorksheetTask