import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';


import App from './App';
import { globalStore } from "./store/index";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/AuthContext';

import "./styles/main.scss";

const CloseIcon = () => (
  <div className={'toastCloseIcon'}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.05033 7.05025C6.46455 7.63604 6.46455 8.58579 7.05033 9.17157L9.87876 12L7.05033 14.8284C6.46455 15.4142 6.46455 16.364 7.05033 16.9497C7.63612 17.5355 8.58587 17.5355 9.17165 16.9497L12.0001 14.1213L14.8285 16.9497C15.4143 17.5355 16.364 17.5355 16.9498 16.9497C17.5356 16.364 17.5356 15.4142 16.9498 14.8284L14.1214 12L16.9498 9.17157C17.5356 8.58579 17.5356 7.63604 16.9498 7.05025C16.364 6.46447 15.4143 6.46447 14.8285 7.05025L12.0001 9.87868L9.17165 7.05025C8.58587 6.46447 7.63612 6.46447 7.05033 7.05025Z" fill="#081C34" />
    </svg>
  </div>
);

(async () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json";
})();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <Provider store={globalStore}>
          <App />
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeButton={<CloseIcon />}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Provider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
