import cx from "classnames";
import ReactMarkdown from 'react-markdown';
import styles from './styles.module.scss';

const ChatMessage = ({ message, isSent }) => {
  return <div className={cx(styles.chatMessage, isSent ? styles.chatMessageSent : '')}>
    <div>
      <ReactMarkdown>{message}</ReactMarkdown>
    </div>
  </div>
}

export default ChatMessage;