import { Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useRef } from 'react';

import { useAuth } from './context/AuthContext';

import mindOverMediaIcon from './assets/icons/mom.png';
import Dashboard from './page/Dashboard/Dashboard';
import LoginPage from './page/LoginPage/LoginPage';

import styles from './styles.module.scss';

const UnAuthApp = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

const AuthApp = () => {
  const { logout } = useAuth()
  return (
    <>
      <div className={styles.appHeader}>
        <img src={mindOverMediaIcon} alt={'logo'} />
        <p className={styles.appHeaderLogOut} onClick={logout}>Log out</p>
      </div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </>
  );
}

const App = () => {
  const { currentUser, logout, token } = useAuth();
  const interceptor = useRef();

  useEffect(() => {
    (interceptor.current !== null) && axios.interceptors.request.eject(interceptor?.current)
    interceptor.current = axios.interceptors.request.use(async (config) => {
      if (!currentUser) {
        return config
      }
      config.headers["Authorization"] = `Bearer ${token}`;
      if (token === null) {
        logout();
        return Promise.reject();
      }
      return config;
    });
  }, [currentUser])

  return (
    <div className={styles.app}>
      {currentUser ? <AuthApp /> : <UnAuthApp />}
    </div>
  );
}

export default App;
