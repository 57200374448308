import React, { useState } from 'react';

import { useAuth } from '../../context/AuthContext';

import mindOverMediaIcon from '../../assets/icons/mom.png'
import { ReactComponent as GoogleIcon } from "../../assets/icons/googleIcon.svg";

import styles from './styles.module.scss'

const LoginPage = () => {
  const { signInWithGoogle } = useAuth();
  const [error, setError] = useState();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      setError(error?.message);
    }
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPageTile}>
        <div className={styles.loginPageTileContainer}>
          <img src={mindOverMediaIcon} alt={'logo'} />
          <div className={styles.loginPageTileButton}>
            <div className={styles.googleButton} onClick={handleGoogleSignIn}>
              <GoogleIcon />
              <p>Connect with Google</p>
            </div>
            <p className={styles.error}>{error}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;